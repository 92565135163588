import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { WeatherDay } from "../WeatherDay/WeatherDay";
import { WeatherToday } from "../WeatherDay/WeatherToday";
import { News} from '../News/News';

import axios from "axios";
import Moment from 'react-moment';
import 'moment-timezone';
import Clock from 'react-live-clock';
import styles from '../WeatherDay/weather.module.css'
import StockTicker from "../News/StockTicker";
import "./infovolo.css"

var data = require('../data/nextday.json');


export const Infovolo = () => {
    var moment = require('moment-timezone');
    //const date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"})
   // const date = moment().tz("Europe/Rome").format();
    const sampleMoment = moment(new Date());
  
    const latitude ='41.8947';
    const longitude ='12.4839';
    //const apiKey = '6e1fb77b704e117d3a8a4433985a4a1e'
    const apiKey = 'cbb5d65769ccd860c8c641c4dcc61588'
    
    const city = 'Roma'
    const lang = 'it'
    //const url = `https://api.openweathermap.org/data/2.5/onecall?lat=${latitude}&lon=${longitude}&appid=${apiKey}&units=metric`
  
    //const url = 'https://www.secure-worldevent.com/totemleo/react/tmp/weather.php';
    
    const url = 'nextday.json';
    //const url = `https://jsonplaceholder.typicode.com/todos/1`

    const [infovolo, setInfovolo] = useState();

    const [today, setToday] = useState([]);
   
  
    const changeMounth = (name) =>{
      if(name == 'January')
        return 'Gennaio'
      if(name == 'February')
        return 'Febbraio'
      if(name == 'March')
        return 'Marzo'
      if(name == 'April')
        return 'Aprile'
      if(name == 'May')
        return 'Maggio'
      if(name == 'June')
        return 'Giugno'
      if(name == 'July')
        return 'Luglio' 
      if(name == 'August')
        return 'Agosto'
      if(name == 'September')
        return 'Settembre'
      if(name == 'October')
        return 'Ottobre'
      if(name == 'November')
        return 'Novembre' 
      if(name == 'December')
        return 'Novembre'
  
  
    }
    
    const Timeout = (time) => {
      let controller = new AbortController();
      setTimeout(() => controller.abort(), time * 1000);
      return controller;
    };
  
  
  
   /* const celsius = ( temperatura ) =>{
      return Math.round(temperatura -273.15)
    } */
  
    const filterWeather = (num) =>{
      
      if( num == "01d" || num == "01n" )
      {
       
          return "https://www.secure-worldevent.com/totemleo/imgs/dist/meteo/sereno.gif";
      }
  
      if(num == "02d" || num == "02n" )
      {
              return "https://www.secure-worldevent.com/totemleo/imgs/dist/meteo/parzialmente-nuovoloso.gif";
              
      }
  
      if(num == "03d" || num == "03n" )
      {
              return "https://www.secure-worldevent.com/totemleo/imgs/dist/meteo/nuvoloso.gif";
              
      }
  
      if(num == "04d" || num == "04n" )
      {
              return "https://www.secure-worldevent.com/totemleo/imgs/dist/meteo/nubi-sparse.gif";
              
      }
  
      if(num == "09d" || num == "09n" )
      {
              return "https://www.secure-worldevent.com/totemleo/imgs/dist/meteo/pioggia-leggera.gif";
              
      }
  
      if(num == "10d" || num == "10n" )
      {
              return "https://www.secure-worldevent.com/totemleo/imgs/dist/meteo/pioggia.gif";
              
      }
  
      if(num == "11d" || num == "11n" )
      {
              return "https://www.secure-worldevent.com/totemleo/imgs/dist/meteo/temporale.gif";
              
      }
  
      if(num == "13d" || num == "13n" )
      {
              return "https://www.secure-worldevent.com/totemleo/imgs/dist/meteo/neve.gif.gif";
              
      }
      if(num == "50d" || num == "50n" )
      {
              return "https://www.secure-worldevent.com/totemleo/imgs/dist/meteo/nebbia.gif";
              
      }
         
      
    }
  
  
    const weatherRotateBackGroundImage = (num) =>{
      if( num == "01d" || num == "01n" )
      {
       
          return "rotate-sereno";
      }
  
      if(num == "02d" || num == "02n" )
      {
          return "rotate-nuvoloso";
              
      }
  
      if(num == "03d" || num == "03n" )
      {
          return "rotate-parzialmentenuvoloso";
      }
  
      if(num == "04d" || num == "04n" )
      {
          return "rotate-nubisparse";
              
      }
  
      if(num == "09d" || num == "09n" )
      {
          return "rotate-pioggialeggera";
              
      }
  
      if(num == "10d" || num == "10n" )
      {
          return "rotate-pioggia";
              
      }
  
      if(num == "11d" || num == "11n" )
      {
          return "rotate-temporale";
              
      }
  
      if(num == "13d" || num == "13n" )
      {
          return "rotate-neve";
              
      }
      if(num == "50d" || num == "50n" )
      {
        return "rotate-nebbia";
              
      }
         
  
    }
  
  
  
  
    useEffect( () => {
     // fetch(`https://api.openweathermap.org/data/2.5/onecall?lat=${latitude}&lon=${longitude}&exclude=hourly,minutely&units=metric&appid=${apiKey}`)
     // fetch(`https://api.openweathermap.org/data/2.5/forecast?q=Rome,it&APPID=${apiKey}&lang=${lang}`)
        fetch(`https://api.openweathermap.org/data/2.5/weather?q=${city},it&appid=${apiKey}&units=metric`)
        .then(data => data.json())
        .then(data => {
          //console.log(data)
            setToday(
              {
                today_temp_min :  Math.round(data.main.temp_min),
                today_temp_max : Math.round(data.main.temp_max),
                today_icon : filterWeather(data.weather[0].icon) ,
                background: weatherRotateBackGroundImage(data.weather[0].icon)
              }
                  
            );
          }) 
          
     },[])
  
  
      const makeAPICall = async () => {
      try {
        const response = await fetch('https://www.secure-worldevent.com/totemleo/react/wheater.php', {mode:'cors'});
        console.log( await response.json() );
        
      }
      catch (e) {
        console.log(e)
      }
    } 
  
  
  
    useEffect( ()=> {
      makeAPICall();
      axios.get('https://www.secure-worldevent.com/totemleo/react/nextday.json')
      //.then( res => console.log(res))
      .then( res =>{
        setInfovolo(res.data.daily.map( df => {
          return{
            min: Math.round(df.temp.min),
            max: Math.round(df.temp.max),
            weatherType: filterWeather(df.weather[0].icon),
            dtime : window.moment(df.dt*1000).format('ddd')
          }
       
       }))
      })
      /*fetch(url)
      console.log(url);
      setWeatherInfo(data.daily.map( df => {
        return{
          min: Math.round(df.temp.min),
          max: Math.round(df.temp.max),
          weatherType: filterWeather(df.weather[0].icon),
          dtime : window.moment(df.dt*1000).format('ddd')
        }
     
     }))
     // fetch(`https://api.openweathermap.org/data/2.5/forecast?q=Rome,it&APPID=${apiKey}&lang={lang}`)
     */
   /*  fetch(url)
         .then( res => res.json())
     //    .then( res => console.log(res))
         .then( res =>{
            setWeatherInfo(res.daily.map( df => {
              return{
                min: Math.round(df.temp.min),
                max: Math.round(df.temp.max),
                weatherType: filterWeather(df.weather[0].icon),
                dtime : window.moment(df.dt*1000).format('ddd')
              }
           
           }))
         }) 
     */    
    },[])
  
  return (
         <div className={today.background}>
          <div className="current-info">
            <div className="future-forecast">
                <div className="weather-forecast-item dataoralogo">
                  <div className="data_top" id="refresh">
                   {sampleMoment.tz("Europe/Rome").format('D')} {changeMounth(sampleMoment.tz("Europe/Rome").format('MMMM')) } {sampleMoment.tz("Europe/Rome").format('YYYY')}
                  </div>
                    <div className="ora_top" id="time">
                    <Clock format={'HH:mm:ss'} ticking={true} timezone={'Europe/Rome'} />
                    <div className="logo_top">
                      <img src="https://www.secure-worldevent.com/totemleo/bkg/logo_alpha_bianco.png" />
                  </div>
                </div>
              </div>  
              {/* today */}
              
              <div className="today" id="current-temp">
                <WeatherToday icon={today.today_icon}  min={today.today_temp_min} max={today.today_temp_max} today="SABATO" />
              </div>
              {/*  end    */}

              {/* Next Day    */}
              <div className="weather-forecast" id="weather-forecast">
                  {!!infovolo && infovolo.filter((item, index) => index < 4).map(( i, index)  =>(
                    <WeatherDay min={i.min} max={i.max} weatherType={i.weatherType} index={i.dtime} key={index } /> 
                  ))}
                </div>
                
              {/*  end    */}

            </div>
          </div>  
          {/**/}
          <div className="metro">
                <div className="box1">
                    <a href="https://infovolo.com/rpartenze2.php" > 
                        <span className="item i1" >
                            <img src="https://www.secure-worldevent.com/totemleo/assets/img/aerei.png" />
                            <span>PARTENZE</span>
                        </span>
                    </a>
                
                    <a href="https://infovolo.com/rarrivi2.php">
                        <span className="item i3">
                            <img src="https://www.secure-worldevent.com/totemleo/assets/img/aerei.png" className="infovolo-arrivi"/> 
                            <span>ARRIVI</span>
                        </span>
                    </a>

                    <div className="box1">
                        <a href="https://www.secure-worldevent.com/totemleo/index.php">
                            <span class="item indietro">
                                <img src="https://www.secure-worldevent.com/totemleo/imgs/home.png" />
                            <span >TORNA INDIETRO</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
          {/**/}
        </div>);
}


