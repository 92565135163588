import styles from './weather.module.css'


export const WeatherDay  = ({min,max,weatherType, index }) =>{
   
const getDayIT =(day) =>{
    if( day == "Mon")
        return "Lun";
    if( day == "Tue")
        return "Mar";
    if( day == "Wed")
        return "Merc";
    if( day == "Thu")
        return "Gio";
    if( day == "Fri")
        return "Ven";
    if( day == "Sat")
        return "Sab";
    if( day == "Sun")
        return "Dom";
}

    
    return(
              <div className="weather-forecast-item">
                <img src={weatherType} className={styles.weatherforecastItemImg}/>
                <div className={styles.others}>
                    <div className={styles.futureForecastTodayDay + " box_giorno"}>{getDayIT(index)}</div>
                    <div className={styles.futureForecastTtodayTemp+ ' temperatura'}>Min &nbsp;{min}°C</div>
                    <div className={styles.futureForecastTtodayTemp+ ' temperatura'}>Max &nbsp;{max}°C</div>
                </div>
             </div>
       
    );
}