import React from "react";
import ReactDom from "react-dom";
import {App} from "./App/App";
import './index.css'

import { Infovolo } from "./InfoVolo/infovolo";





ReactDom.render(
    
    <React.StrictMode>
      
        <App />
      
       
    </React.StrictMode>,
    document.getElementById('root')
    
)


