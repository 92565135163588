import React, { useEffect, useState } from "react";

import { Link, BrowserRouter as Router } from "react-router-dom";

import { WeatherDay } from "../WeatherDay/WeatherDay";
import { WeatherToday } from "../WeatherDay/WeatherToday";
import { News } from "../News/News";
import "./App.css";
import axios from "axios";
import Moment from "react-moment";
import "moment-timezone";
import Clock from "react-live-clock";
import styles from "../WeatherDay/weather.module.css";
import StockTicker from "../News/StockTicker";
import Loader from "../components/Loader";

import { Backdrop } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
var data = require("../data/nextday.json");

export const App = () => {
  let search = window.location.search;
  //search.split("=")[1]

  const totem = search.split("=")[1];

  /* 
 COMMENTO PER BUG
 React.useEffect(() => {
    setTimeout(() => {
      window.location.replace("https://centroleonardopwa.online/intro-promozioni.php?totem="+totem);
    }, 5000)
  }, []);
*/

  var moment = require("moment-timezone");
  //const date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"})
  // const date = moment().tz("Europe/Rome").format();
  const sampleMoment = moment(new Date());

  const latitude = "41.8947";
  const longitude = "12.4839";
  //const apiKey = '6e1fb77b704e117d3a8a4433985a4a1e'
  const apiKey = "cbb5d65769ccd860c8c641c4dcc61588";

  const city = "Roma";
  const lang = "it";
  //const url = `https://api.openweathermap.org/data/2.5/onecall?lat=${latitude}&lon=${longitude}&appid=${apiKey}&units=metric`

  //const url = 'https://www.secure-worldevent.com/totemleo/react/tmp/weather.php';

  const url = "nextday.json";
  //const url = `https://jsonplaceholder.typicode.com/todos/1`
  const [weatherInfo, setWeatherInfo] = useState();
  const [today, setToday] = useState([]);

  const changeMounth = (name) => {
    if (name == "January") return "Gennaio";
    if (name == "February") return "Febbraio";
    if (name == "March") return "Marzo";
    if (name == "April") return "Aprile";
    if (name == "May") return "Maggio";
    if (name == "June") return "Giugno";
    if (name == "July") return "Luglio";
    if (name == "August") return "Agosto";
    if (name == "September") return "Settembre";
    if (name == "October") return "Ottobre";
    if (name == "November") return "Novembre";
    if (name == "December") return "Novembre";
  };

  const Timeout = (time) => {
    let controller = new AbortController();
    setTimeout(() => controller.abort(), time * 1000);
    return controller;
  };

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  /* const celsius = ( temperatura ) =>{
    return Math.round(temperatura -273.15)
  } */

  const filterWeather = (num) => {
    console.log("=> " + num);

    if (num == "800") {
      return "https://centroleonardopwa.online/imgs/dist/meteo/sereno.gif";
    }

    if (num == "801") {
      return "https://centroleonardopwa.online/imgs/dist/meteo/parzialmente-nuovoloso.gif";
    }

    if (num == "802") {
      return "https://centroleonardopwa.online/imgs/dist/meteo/nubi-sparse.gif";
    }

    if (num == "803" || num == "804") {
      return "https://centroleonardopwa.online/imgs/dist/meteo/nuvoloso.gif";
    }

    if (num == "500" || num == "501" || num == "300" || num == "301") {
      return "https://centroleonardopwa.online/imgs/dist/meteo/pioggia-leggera.gif";
    }

    if ((num > "302" && num < "321") || (num >= "502" && num < "531")) {
      return "https://centroleonardopwa.online/imgs/dist/meteo/pioggia.gif";
    }

    if (num > "200" && num < "232") {
      return "https://centroleonardopwa.online/imgs/dist/meteo/temporale.gif";
    }

    if (num > "600" && num < "622") {
      return "https://centroleonardopwa.online/imgs/dist/meteo/neve.gif.gif";
    }
    if (num > "701" && num < "781") {
      return "https://centroleonardopwa.online/imgs/dist/meteo/nebbia.gif";
    }
  };

  const weatherRotateBackGroundImage = (num) => {
    if (num == "800") {
      return "rotate-sereno";
    }

    if (num == "801") {
      return "rotate-parzialmentenuvoloso";
    }

    if (num == "802") {
      return "rotate-nubisparse";
    }

    if (num == "803" || num == "804") {
      return "rotate-nuvoloso";
    }

    if (num == "500" || num == "501" || num == "300" || num == "301") {
      return "rotate-pioggialeggera";
    }

    if ((num > "302" && num < "321") || (num > "502" && num < "531")) {
      return "rotate-pioggia";
    }

    if (num > "200" && num < "232") {
      return "rotate-temporale";
    }

    if (num > "600" && num < "622") {
      return "rotate-neve";
    }
    if (num > "701" && num < "781") {
      return "rotate-nebbia";
    }
  };

  useEffect(() => {
    // fetch(`https://api.openweathermap.org/data/2.5/onecall?lat=${latitude}&lon=${longitude}&exclude=hourly,minutely&units=metric&appid=${apiKey}`)
    // fetch(`https://api.openweathermap.org/data/2.5/forecast?q=Rome,it&APPID=${apiKey}&lang=${lang}`)
    fetch(
      `https://api.openweathermap.org/data/2.5/weather?q=${city},it&appid=${apiKey}&units=metric`
    )
      .then((data) => data.json())
      .then((data) => {
        setToday({
          today_temp_min: Math.round(data.main.temp_min),
          today_temp_max: Math.round(data.main.temp_max),
          today_icon: filterWeather(data.weather[0].id),
          background: weatherRotateBackGroundImage(data.weather[0].id),
        });
      });
  }, []);

  const makeAPICall = async () => {
    try {
      const response = await fetch(
        "https://centroleonardopwa.online/wheater.php",
        { mode: "cors" }
      );
      console.log(await response.json());
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    makeAPICall();
    axios
      .get("https://centroleonardopwa.online/nextday.json")
      //.then( res => console.log(res))
      .then((res) => {
        setWeatherInfo(
          res.data.daily.map((df) => {
            return {
              min: Math.round(df.temp.min),
              max: Math.round(df.temp.max),
              weatherType: filterWeather(df.weather[0].id),
              dtime: window.moment(df.dt * 1000).format("ddd"),
            };
          })
        );
      });
    /*fetch(url)
    console.log(url);
    setWeatherInfo(data.daily.map( df => {
      return{
        min: Math.round(df.temp.min),
        max: Math.round(df.temp.max),
        weatherType: filterWeather(df.weather[0].icon),
        dtime : window.moment(df.dt*1000).format('ddd')
      }
   
   }))
   // fetch(`https://api.openweathermap.org/data/2.5/forecast?q=Rome,it&APPID=${apiKey}&lang={lang}`)
   */
    /*  fetch(url)
       .then( res => res.json())
   //    .then( res => console.log(res))
       .then( res =>{
          setWeatherInfo(res.daily.map( df => {
            return{
              min: Math.round(df.temp.min),
              max: Math.round(df.temp.max),
              weatherType: filterWeather(df.weather[0].icon),
              dtime : window.moment(df.dt*1000).format('ddd')
            }
         
         }))
       }) 
   */
  }, []);

  const [showLoader, setShowLoader] = useState(false);

  const onShowLoader = () => {
    setShowLoader(true);
    setTimeout(() => {
      setShowLoader(false);
    }, 5 * 1000);
  };

  return (
    <Router>
      <>
        <div className={today.background}>
          <div className="current-info">
            <div className="future-forecast">
              <div className="weather-forecast-item dataoralogo">
                <div className="data_top" id="refresh">
                  {sampleMoment.tz("Europe/Rome").format("D")}{" "}
                  {changeMounth(sampleMoment.tz("Europe/Rome").format("MMMM"))}{" "}
                  {sampleMoment.tz("Europe/Rome").format("YYYY")}
                </div>
                <div className="ora_top" id="time">
                  <Clock
                    format={"HH:mm:ss"}
                    ticking={true}
                    timezone={"Europe/Rome"}
                  />
                  <div className="logo_top">
                    <img src="https://centroleonardopwa.online/v2/assets/img/logo-white.png?v=2" />
                  </div>
                </div>
              </div>
              {/* today */}

              <div className="today" id="current-temp">
                {!!weatherInfo &&
                  weatherInfo
                    .filter((item, index) => index >= 0 && index < 1)
                    .map((i, index) => (
                      <WeatherToday
                        icon={today.today_icon}
                        min={i.min}
                        max={i.max}
                        today="SABATO"
                      />
                    ))}
              </div>
              {/*  end    */}

              {/* Next Day    */}
              <div className="weather-forecast" id="weather-forecast">
                {!!weatherInfo &&
                  weatherInfo
                    .filter((item, index) => index > 0 && index < 5)
                    .map((i, index) => (
                      <WeatherDay
                        min={i.min}
                        max={i.max}
                        weatherType={i.weatherType}
                        index={i.dtime}
                        key={index}
                      />
                    ))}
              </div>

              {/*  end    */}
            </div>
          </div>

          {/* start button */}

          <div className="metro">
            <div className="box1">
              <a href={
                "https://centroleonardopwa.online/eventinews.php?totem=" + totem
              }>
                <span className="item i7">
                  <img src="https://centroleonardopwa.online/imgs/dist/eventi.gif" />
                  <span className="weather_boxEventiNews__7WW97">
                    Eventi &amp; News
                  </span>
                  <p>&nbsp;</p>
                </span>
              </a>
              <a href={
                "https://centroleonardopwa.online/promo/promozioni.php?totem=" + totem
              }>
                <span className="item i6">
                  <img src="https://centroleonardopwa.online/imgs/dist/promo-e-news.gif" />
                  <span className="weather_boxPromozioni__fAVBG">
                    Promozioni{" "}
                  </span>
                </span>
              </a>
              <a href={
                "https://centroleonardopwa.online/lavoro/lavoro.php?totem=" + totem
              }>
                <span className="item i4">
                  <img src="https://centroleonardopwa.online/imgs/dist/lavoro.gif" />
                  <span className="weather_boxLavoro__Gfj8a">trova Lavoro</span>
                </span>
              </a>
              <a href={
                "https://centroleonardopwa.online/club-suuuper.php?totem="+totem
              }>
                <span class="item i10">
                  <img src="https://centroleonardopwa.online/imgs/dist/suuuper-logo.png" />
                  <span class="weather_boxLavoro__Gfj8a">Club Vantaggi</span>
                </span>
              </a>
              <a href={
                "https://centroleonardopwa.online/mappa/noiframe.php?totem=" + totem
              }>
                <span className="item i9">
                  <img
                    src="https://centroleonardopwa.online/imgs/dist/pianta.gif"
                    className="weather_boxImagePianta_np9Xr"
                  />
                  <span className="weather_boxPiantaNegozi_lBv4k">
                    Negozi &amp; Servizi
                  </span>
                </span>
              </a>


              <a href={
                "https://centroleonardopwa.online/contatti.php?totem=" + totem
              }>
                <span className="item i5">
                  <img src="https://centroleonardopwa.online/imgs/dist/contatti.gif" />
                  <span className="weather_boxContatti__DcIsJ">Contatti</span>
                </span>
              </a>

              <a href={"https://centroleonardopwa.online/wooonder-pass.php?totem="+totem}>
                <span className="item i12">
                  <img src="https://centroleonardopwa.online/imgs/dist/logo-wooonder-pass.png" />
                    <span className="weather_boxContatti__DcIsJ">Wooonder Pass</span>
                </span>
              </a>

              <a href={
                "https://centroleonardopwa.online/intro-voli.php?totem=" + totem
              }>
                <span className="item i1">
                  <img src="https://centroleonardopwa.online/imgs/dist/aerei.gif" />
                  <span className="weather_boxInfovoli__frOzF">
                    Orario &nbsp; Voli
                  </span>
                </span>
              </a>
              <a
                href={
                  "https://centroleonardopwa.online/treni.php?totem=" + totem
                } style={{ color: "white" }}
              >
                <span className="item i3">
                  <img src="https://centroleonardopwa.online/imgs/dist/treni.gif" />
                  <span className="weather_boxTreni__8C3N-">Orario Treni</span>
                </span>
              </a>
              <a href={
                "https://centroleonardopwa.online/ilmeteo/index.php?totem=" + totem
              }>
                <span className="item i8">
                  <div id="meteo_evento">
                    <img src="https://centroleonardopwa.online/imgs/dist/meteo.gif" />
                    <span className="weather_boxMeteo__U4qhI">Meteo</span>
                  </div>
                  <p>&nbsp;</p>
                </span>
              </a>
              <a href={
                "https://centroleonardopwa.online/cinema/cinema.php?totem=" + totem
              }>
                <span className="item i11">
                  <img src="https://centroleonardopwa.online/imgs/dist/logo-uci.png" />
                  <span className="weather_boxContatti__DcIsJ">Orari Cinema</span>
                </span>
              </a>
            </div>
          </div>

          {/* <div className="metro">
            <div className="box1">
                <a href="/totemleo/intro-voli.php" > 
                <span className="item i1" >
                   <img src="https://www.secure-worldevent.com/totemleo/imgs/dist/aerei.gif" />
                   <span  className={styles.boxInfovoli}>Orario &nbsp; Voli</span>
                </span>
                </a>
                <a href="https://www.secure-worldevent.com/totemleo/treni.php" style={{color:'white'}}> 
                <span className="item i3">
                
                      <img src="https://www.secure-worldevent.com/totemleo/imgs/dist/treni.gif" />
                      <span className={styles.boxTreni}>Orario Treni</span>
                    </span>
                </a>

                <a href="https://www.secure-worldevent.com/totemleo/lavoro.php" >
                  <span className="item i4">
                        <img src="https://www.secure-worldevent.com/totemleo/imgs/dist/lavoro.gif" />
                        <span className={styles.boxLavoro}>trova Lavoro</span>
                  </span>
                </a>
                <a href="https://www.secure-worldevent.com/totemleo/contatti.php">
                  <span className="item i5">
                      <img src="https://www.secure-worldevent.com/totemleo/imgs/dist/contatti.gif" />
                      <span className={styles.boxContatti}>Contatti</span>
                  </span>
                </a>
                <a href="https://www.secure-worldevent.com/totemleo/promozioni.php">
                  <span className="item i6">
                     <img src="https://www.secure-worldevent.com/totemleo/imgs/dist/promo-e-news.gif" />
                     <span className={styles.boxPromozioni}>Promozioni </span>
                  </span>
                </a>
          
                <a href="https://www.secure-worldevent.com/totemleo/eventinews.php">
                  <span className="item i7">
                     <img src="https://www.secure-worldevent.com/totemleo/imgs/dist/eventi.gif" />
                      <span className={styles.boxEventiNews}>Eventi News</span>
                      <p>&nbsp;</p>
                      </span>
                </a>
            
                <a href="https://www.secure-worldevent.com/totemleo/lapiantina.php">
                  <span className="item i9">
                      <img src="https://www.secure-worldevent.com/totemleo/imgs/dist/pianta.gif" className={styles.boxImagePianta} style={{width:"90%"}}/>
                      <span className={styles.boxPiantaNegozi}>Pianta & Negozi</span>
                  </span>
                </a>
                <a href="https://www.secure-worldevent.com/totemleo/ilmeteo/index.html">
                  <span className="item i8">
                    <div id="meteo_evento">
                      <img src="https://www.secure-worldevent.com/totemleo/imgs/dist/meteo.gif" />
                      <span className={styles.boxMeteo}>Meteo</span>
                    </div>
                    <p>&nbsp;</p>
                  </span>
                </a>
            </div>
           

             
                  </div> */}

          {/*end button*/}

          {/* NEWS */}
          {/*<StockTicker /> */}

          <News />

          {/*end News */}

          <div></div>
        </div>
      </>
    </Router>
  );
};
