import React, {createContext, useEffect,useState} from 'react'
import Ticker from 'react-ticker'


import news from './news.module.css'

export const News = (props) =>{
    //const apiKey ='779d8df07c684f3ebb08f48b5a1e311f';
    const apiKey = '2fc8e273d45c46b6a98545dcc96c74b3'
    let [value, setValue] = useState([])
    

   

    return(
        
        <div className={news.wrapper}>
            
            <iframe scrolling='no' width='1080px' height='145px' src='https://centroleonardopwa.online/banner_news/banner_news.html' aria-current='page' border='0' style={{border:0}}></iframe>
                    {/*<div className='logo_bottom_news'>
                        <img src="https://www.secure-worldevent.com/totemleo/bkg/logo-centro-leo-bottom.png"/>  
                    </div>
                    
                      <div className="breaking-alert">
                      <span style={{ color:'black'}}>Breaking News</span>
                    </div>
              
            
            <div className={news.breakingAlertWrapper} >
                <div className="title item-title"  > 
                    <div class="bordo-breaking"></div>
                    <a href=""  onclick="return false;" >
                        <iframe scrolling='no'  width='100%' height='60' src='https://www.agi.it/embed.html' aria-current="page">
                        </iframe>
                    </a>
                </div>
                <div className="s">
                    <div className="r">
                
                    </div>
                </div>
                </div> */}
        </div>
    )
} 