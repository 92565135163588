import styles from './weather.module.css'

export const WeatherToday = ({icon, max, min, today}) =>{
    return(
            <div className="weather-forecast-item">
                 <div className={styles.futureForecastTodayDay +' box_giorno_oggi'}>OGGI</div>
                <img src={icon} alt="weather icon"  className={styles.weatherforecastItemImg}/>
                <div className={styles.futureForecastTtodayTemp+ ' temperatura'}>Min  {min}°C</div>
                <div className={styles.futureForecastTtodayTemp+ ' temperatura'}>Max  {max}°C</div>
            </div>
        
    )
}